import * as React from "react";
import axios from "axios";
import styled from "@emotion/styled";
import queryString from "query-string";
import { Input } from "@fitplan/lib/components/Input";
import { useConfig } from "@fitplan/context/lib-es/config";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";
import { PageTitle } from "@fitplan/lib/components/Header/PageTitle";
import { BrandButton } from "@fitplan/lib/components/Button/BrandButton";
import { PageSubTitle } from "@fitplan/lib/components/Header/PageSubTitle";

import PageWrapper from "../components/pageWrapper/PageWrapper";
import useMount from "react-use/lib/useMount";

export interface Props {}

const Login: React.SFC<Props> = props => {
    const { access_token, user } = useAuthContext();
    const { apiBaseUrl } = useConfig();
    const isPaidMember = user?.isPaidMember || false;
    const [code, setCode] = React.useState<string>("");
    const [error, setError] = React.useState<string>("");
    const [shared, setShared] = React.useState<boolean>(false);
    const [pending, setPending] = React.useState<boolean>(false);
    const url = code
        ? `https://referral.fitplanapp.com/?accessCode=${code}`
        : "";
    const [codeCopied, setCodeCopied] = React.useState(false);

    const submit = React.useRef<HTMLButtonElement>();
    const onClick = async () => {
        if (pending) return;
        if (submit.current) {
            submit.current.disabled = true;
        }
        setPending(true);
        setError("");
        setShared(false);
        try {
            const result = await axios({
                url: `${apiBaseUrl}/v1/referral/create`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            if (result?.data?.result?.recipientCode) {
                setCode(result.data.result.recipientCode);
            } else {
                setError("Unknown error. Please contact support.");
            }
        } catch (err) {
            if (err?.response?.data?.error) {
                setError(err.response.data.error);
            } else {
                setError(`Unknown error: ${err}`);
            }
        }
        setPending(false);
    };

    const onShare = async () => {
        const text = `Let's get fit together! Train with me on Fitplan app for 30 days, FREE of charge. Click the link below to access to hundreds of celebrity home workout plans & join the Fitplan community now.`;
        if (typeof navigator.share !== "undefined") {
            try {
                await navigator.share({ text, url });
                setShared(true);
            } catch (err) {
                console.error("Share error", err);
            }
        } else {
            try {
                await navigator.clipboard.writeText(`${text} ${url}`);
                setCodeCopied(true);
            } catch (err) {
                setError("Failed to copy to clipboard");
            }
        }
    };

    return (
        <PageWrapper {...props}>
            <div>
                {isPaidMember ? (
                    <>
                        <img
                            className="hidden md:block w-full h-auto"
                            src="https://images.fitplanapp.com/web/landing/referral/desktop-paid.jpg"
                        />
                        <img
                            className="md:hidden w-full h-auto"
                            src="https://images.fitplanapp.com/web/landing/referral/mobile-paid.jpg"
                        />
                    </>
                ) : (
                    <>
                        <img
                            className="hidden md:block w-full h-auto"
                            src="https://images.fitplanapp.com/web/landing/referral/desktop-free.jpg"
                        />
                        <img
                            className="md:hidden w-full h-auto"
                            src="https://images.fitplanapp.com/web/landing/referral/mobile-free.jpg"
                        />
                    </>
                )}
            </div>
            {user ? (
                <>
                    <PageTitle>Share Fitplan with a Friend</PageTitle>
                    {!isPaidMember && (
                        <SubTitle>
                            Your 30 days of premium access will begin when
                            recipient redeems their referral offer.
                        </SubTitle>
                    )}
                    {code ? (
                        <>
                            <SubTitle>
                                Invite Code Created! Share it now:{" "}
                                <a href={url}>{url}</a>
                            </SubTitle>
                            {shared ? (
                                <>
                                    <SubTitle>
                                        Referral sent!
                                        <a href={url}>{url}</a>
                                    </SubTitle>
                                    <Submit
                                        ref={submit}
                                        disabled={pending}
                                        type="button"
                                        onClick={onClick}
                                    >
                                        Get Another Referral Link
                                    </Submit>
                                    <SubTitle>
                                        <span style={{ color: "red" }}>
                                            {error}&nbsp;
                                        </span>
                                    </SubTitle>
                                </>
                            ) : (
                                <>
                                    <Submit type="button" onClick={onShare}>
                                        {typeof navigator !== "undefined" &&
                                        typeof navigator.share === "function"
                                            ? "Share Link"
                                            : "Copy Link to Clipboard"}
                                    </Submit>
                                    {codeCopied && (
                                        <SubTitle>
                                            Copied to clipboard!
                                        </SubTitle>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Submit
                                ref={submit}
                                disabled={pending}
                                type="button"
                                onClick={onClick}
                            >
                                Get Referral Link
                            </Submit>
                            <SubTitle>
                                <span style={{ color: "red" }}>
                                    {error}&nbsp;
                                </span>
                            </SubTitle>
                        </>
                    )}
                </>
            ) : (
                <PageTitle>You must be logged in to send a referral</PageTitle>
            )}
            <Polygon polygonColor="#111213" borderColor="#00ffb3" />
        </PageWrapper>
    );
};

const SubTitle = styled(PageSubTitle)`
    margin-top: 24px;
`;

const Submit = styled(BrandButton)`
    flex: 0 0 auto;

    width: 200px;
    line-height: 24px;
    margin: auto;
    margin-top: 16px;
`;

export default Login;
